import { NgModule, Optional, Self } from '@angular/core';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';
import { BrowserGuard, RootGuard, TenantOverrideGuard } from '@core/guards';
import { logger } from '@core/helpers/logger';
import { CleanLayoutComponent } from '@core/layouts/clean/clean-layout.component';

import { ConstructorLayoutComponent } from '@core/layouts/constructor-layout/constructor-layout.component';
import { LandingLayoutComponent } from '@core/layouts/landing/landing-layout.component';
import { MainLayoutComponent } from '@core/layouts/main/main-layout.component';
import { ProfileLayoutComponent } from '@core/layouts/profile/profile-layout.component';

import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MergedRouterStateSerializer, routerStateConfig } from '@store/reducers/router.reducer';
import { EContentPanelRoute } from 'lingo2-models';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CleanLayoutComponent,
    canActivateChild: [TenantOverrideGuard, RootGuard],
    data: { page_style: 'job', preload: true },
    loadChildren: () => import('./landings/job/job.module').then((m) => m.JobModule),
  },
  {
    path: '',
    component: LandingLayoutComponent,
    data: { page_style: 'article' }, // другой стиль
    children: [
      {
        path: 'privacy',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'terms',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'about',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'offer',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'salesrefund',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'old/overview-webinar',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
      {
        path: 'collection-licenses',
        loadChildren: () => import('./landings/article/article.module').then((m) => m.ArticleModule),
        data: { preload: true },
      },
    ],
  },
  {
    path: '',
    component: CleanLayoutComponent,
    data: { page_style: 'job', preload: true },
    loadChildren: () => import('./landings/job/job.module').then((m) => m.JobModule),
  },
  {
    path: '',
    component: ConstructorLayoutComponent,
    children: [
      {
        path: 'constructor',
        loadChildren: () => import('./content-editor/content-editor.module').then((m) => m.ContentEditorModule),
        data: { preload: true },
        canLoad: [BrowserGuard],
        // canActivate: [ AuthGuard ],
      },
      {
        path: 'content/collections',
        canLoad: [BrowserGuard],
        loadChildren: () =>
          import('./collections-constructor/collections-constructor.module').then(
            (m) => m.CollectionsConstructorModule,
          ),
        data: { preload: true },
      },
    ],
  },
  {
    path: 'support',
    component: CleanLayoutComponent,
    data: { page_style: 'support', preload: true },
    loadChildren: () => import('./landings/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'auth',
    component: CleanLayoutComponent,
    canLoad: [BrowserGuard],
    loadChildren: () => import('./auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    data: { preload: true },
  },
  {
    path: 'confirm-email',
    component: CleanLayoutComponent,
    canLoad: [BrowserGuard],
    loadChildren: () => import('./account/confirm-email-routing.module').then((m) => m.ConfirmEmailRoutingModule),
    data: { preload: true },
  },
  {
    path: 'profile',
    component: ProfileLayoutComponent,
    data: { page_style: 'profile', preload: true },
    loadChildren: () => import('./landings/profile/profile-page.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'profile/:step',
    component: ProfileLayoutComponent,
    data: { page_style: 'profile', preload: true },
    loadChildren: () => import('./landings/profile/profile-page.module').then((m) => m.ProfilePageModule),
  },
  {
    path: EContentPanelRoute.schools + '/:slug/settings',
    loadChildren: () => import('./school-settings/school-settings.module').then((m) => m.SchoolSettingsModule),
    data: { preload: true },
  },
  /**
   * Ниже идут контентные страницы.
   * Они также обрабатывают 404 страницу,
   * так что размещать что-то ниже этого блока бессмысленно
   */
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'u',
        loadChildren: () => import('./account/account-routing.module').then((m) => m.AccountRoutingModule),
        data: { preload: true },
      },
      {
        path: 'me',
        canLoad: [BrowserGuard],
        loadChildren: () => import('./account/account-me-routing.module').then((m) => m.AccountMeRoutingModule),
        data: { preload: true },
      },
      {
        path: 'schedule',
        canLoad: [BrowserGuard],
        loadChildren: () => import('./schedule-page/schedule-page.module').then((m) => m.SchedulePageModule),
        data: { preload: true },
      },
      {
        path: 'my-feed',
        loadChildren: () => import('./feed/feed.module').then((m) => m.FeedModule),
        data: { preload: true },
      },
      {
        path: 'my-vocabulary',
        canLoad: [BrowserGuard],
        loadChildren: () => import('./vocabulary/vocabulary.module').then((m) => m.VocabularyModule),
        data: { preload: true },
      },
      {
        // СТАРЫй роут для редиректов на страницу урока по короткой ссылке
        path: 'go/lessons/:user/:slug',
        loadChildren: () =>
          import('./library/content-slug-redirect-routing.module').then((m) => m.ContentSlugRedirectRoutingModule),
        data: { preload: true },
      },
      {
        // роут для редиректов на страницу урока по короткой ссылке
        path: 'go/lessons/:slug',
        loadChildren: () =>
          import('./library/content-slug-redirect-routing.module').then((m) => m.ContentSlugRedirectRoutingModule),
        data: { preload: true },
      },
      {
        // роут для быстрого создания класса
        path: 'go/class',
        canLoad: [BrowserGuard],
        loadChildren: () => import('./meetings/meeting-fast-start.module').then((m) => m.MeetingFastStartModule),
        data: { preload: true },
      },
      {
        // роут для присоединения к классу и открытию конференции
        path: 'classes/join',
        loadChildren: () => import('./meetings/meeting-join.module').then((m) => m.MeetingJoinModule),
        data: { preload: true },
      },

      {
        path: 'users',
        canLoad: [BrowserGuard],
        loadChildren: () => import('./users/users-routing.module').then((m) => m.UsersRoutingModule),
        data: { preload: true },
      },
      {
        path: 'notifications',
        canLoad: [BrowserGuard],
        loadChildren: () =>
          import('./notifications/notifications-page/notifications-page.module').then((m) => m.NotificationsPageModule),
        data: { preload: true },
      },
      // разделы-черновики выключены
      // {
      //   path: 'games',
      //   loadChildren: () => import('./games/games-routing.module').then(m => m.GamesRoutingModule),
      // },
      // {
      //   path: 'tasks',
      //   canLoad: [ BrowserGuard ],
      //   loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
      // },
      // ??? {
      // ???   path: EContentPanelRoute.classes,
      // ???   loadChildren: () => import('./meetings/meetings-routing.module').then((m) => m.MeetingsRoutingModule),
      // ??? },
      {
        path: EContentPanelRoute.schools + '/:slug',
        loadChildren: () => import('./schools/schools.module').then((m) => m.SchoolsModule),
        data: { preload: true },
      },
      {
        path: 'classrooms',
        loadChildren: () => import('./classrooms/classrooms.module').then((m) => m.ClassroomsModule),
        data: { preload: true },
      },

      /** Универсальный роутинг для всего остального (news/home/lessons, etc...) */
      {
        path: '',
        loadChildren: () => import('./content-routing.module').then((m) => m.ContentRoutingModule),
        data: { preload: true },
      },
      {
        path: '**',
        loadChildren: () => import('./content-routing.module').then((m) => m.ContentRoutingModule),
        data: { preload: true },
      },
    ],
  },
];

@NgModule({
  imports: [
    TranslateModule,
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    RouterModule.forRoot(routes, {
      /**
       * Выключена инициализации навигации сразу по загрузке приложения,
       * чтобы успели считаться токены авторизации для работы GuestGuard, AuthGuard
       * Инициализация запускается в AppComponent.enableAngularRouting()
       *
       * @see AppComponent.enableAngularRouting()
       */
      initialNavigation: 'disabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
  ],
})
export class AppRoutingModule {
  public constructor(@Self() @Optional() router: Router) {
    if (router) {
      // logger.log('All good, NgrxRouterStoreModule');
    } else {
      logger.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
    }
  }
}
